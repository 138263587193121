import React from 'react';

import SignInWrapper from '../components/Containers/SignInWrapper';
import Layout from '../components/layout';
import SignInForm from '../components/SignIn';

const SignInPage = () => (
  <SignInWrapper>
    <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-800">
      Client Portal
    </h1>
    <p className="mt-2 text-center text-sm leading-5 text-gray-600">
      Sign In To Your Account
    </p>

    <SignInForm />
  </SignInWrapper>
);

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
